import React from 'react';
import './SocialMedia.css'

import { AiOutlineDiscord } from "react-icons/ai";
import { TbBrandMastodon } from "react-icons/tb";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineLinkedin } from "react-icons/ai";

const SocialMedia: React.FC = () => {
    const isMobile = window.innerWidth < 768;
    return (
        <section id="social-media">
            <div>
                <h2>Knowledge is Power:</h2>
                <h2 className='heading-spacing'>Tap into the Collective Consciousness</h2>
            </div>
            <div>
                <p>Show Us Your Buds!</p>
                <p>Share your progress, ask questions, and connect with fellow growers in our up-and-coming community. </p>
                <p>Join the conversation and tag us in your grow pics using #GreenGrowersClub</p>
            </div>
            <div>
                <a href="https://discord.gg/AjRQSUGc6u" target="_blank" rel="noopener noreferrer">
                    <AiOutlineDiscord size={isMobile ? 90 : 125}/>
                </a>
                <a href="https://mastodon.social/@greengrowersclub" target="_blank" rel="noopener noreferrer">
                    <TbBrandMastodon size={isMobile ? 90 : 125}/>
                </a>
                <a href="https://www.instagram.com/greengrowersclub" target="_blank" rel="noopener noreferrer">
                    <AiOutlineInstagram size={isMobile ? 90 : 125}/>
                </a>
                <a href="https://www.linkedin.com/company/green-growers-club" target="_blank" rel="noopener noreferrer">
                    <AiOutlineLinkedin size={isMobile ? 90 : 125}/>
                </a>
            </div>
        </section>
);
};

export default SocialMedia;
