import React from 'react';
import Row from "react-bootstrap/Row";
import './ComingSoon.css';
import Col from "react-bootstrap/Col";
import '@samlam140330/bulma-timeline/dist/css/bulma-timeline.min.css';

const ComingSoon: React.FC = () => {
    const items = [
        {
            date: "November '24",
            text: 'Add logs to grow ops and cultivars, improve cultivar management UX and task/log viewing, update Strain Library',
        },
        {
            date: 'January \'25',
            text: 'All Cultivars & Strain Library with strain genetic disposition, add fine-tuned tasks with notifications based on SOPs',
        },
        {
            date: 'March \'25',
            text: 'Implement AI image recognition with general advice, add harvest reports, create community social feed',
        },
        {
            date: 'May \'25',
            text: 'Integrate general hardware API to feed grow ops and cultivars\' logs, implement reward system, improve gear and strain libraries',
        }
    ]

    return (
        <section id="coming-soon">
            <h2>Expanding The Grow-Verse</h2>
            <Row>
                <Col>
                    <p>
                        Green Growers Club is constantly evolving to meet the needs of our
                        community.
                    </p>
                    <p>Here's a glimpse into what's on the horizon:</p>
                </Col>
            </Row>

            <div className="timeline">
                {items.map((item, index) => (
                    <div className="timeline-item" key={index}>
                        <div className="timeline-marker"></div>
                        <div className="timeline-content">
                            <p className="heading">{item.date}</p>
                            <p>{item.text}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
};

export default ComingSoon;
