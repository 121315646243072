import './Hero.css';

import React, {useEffect} from 'react';
import { FaAngleDown } from "react-icons/fa";

const loadScript = (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
    });
};

const loadVantaScripts = async () => {
    try {
        await loadScript("https://cdnjs.cloudflare.com/ajax/libs/three.js/r121/three.min.js");
        await loadScript("https://cdn.jsdelivr.net/npm/vanta@latest/dist/vanta.fog.min.js");
        setVanta();
    } catch (error) {
        console.error(error);
    }
};

const setVanta = () => {
    if ((window as any).VANTA) {
        (window as any).VANTA.FOG({
            el: "#hero",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            highlightColor: 0xffd1dc,
            midtoneColor: 0xf0d9b9,
            lowlightColor: 0xffccbc,
            baseColor: 0xb8aec3,
            zoom: 1.4,
            speed: 2.5,
            blurFactor: 0.7,
            scale: 1.0,
            scaleMobile: 1.0
        });
    }
};

const Hero: React.FC = () => {
    const scrollTo = (id: string) => {
        const element = document.getElementById(id) as HTMLDivElement;
        element.scrollIntoView({
            behavior: "smooth",
        });
    };

    useEffect(() => {
        loadVantaScripts();
        return () => {
            if ((window as any).VANTA) {
                (window as any).VANTA.current?.destroy();
            }
        };
    }, []);

  return (
      <section id="hero">
          <div className="hero-content">
            <h1>Green<br />Growers<br />Club</h1>
            <p className="slogan">Be Kind To Your Buds</p>
            <FaAngleDown className="scroll-button" onClick={() => {scrollTo('about')}}/>
          </div>
      </section>
  );
};

export default Hero;
