import React from 'react';
import ios from '../assets/images/applestore.svg';
import './MobileDownload.css';

const MobileDownload: React.FC = () => {
    return (
        <section id="mobile-download">
            <div>
                <h2>Are You Ready To Elevate Your Grow Ops?</h2>
                <p>Download Green Growers Club and unleash your cultivars potential.</p>
            </div>
            <div className="stores">
                <img className="stores-apple" src={ios} alt="apple store"/>
                <span className='stores-text'>Coming Soon!</span>
            </div>
        </section>
    );
};

export default MobileDownload;
