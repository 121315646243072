import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TaC from "./pages/TaC";
import Home from "./pages/Home";

const App: React.FC = () => {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" index element={<Home />} />
              <Route path='/terms' element={<TaC />}>
            </Route>
          </Routes>
      </BrowserRouter>
  );
};

export default App;
