import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './TaC.css';

const Footer: React.FC = () => {
    return (
        <section id='terms-and-conditions'>
            <Row>
                <Col>
                    <h1>Green Growers Club</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Terms and Conditions</h2>
                    <p>Be Kind To Your Buds</p>
                    <p>Last Updated: 2024-10-02</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Mobile App Terms of Service</h2>
                    <p>
                        These Terms of Service ("Terms") govern your access to and use of the Green Growers Club website and
                        mobile application (collectively, the "Services"). By accessing or using the Services, you ("User") agree to be bound by these Terms.
                        You represent and warrant that you are at least twenty-one (21) years of age and possess the legal capacity to enter into this agreement.
                        If you do not agree to these Terms, you are prohibited from using the Services.
                    </p>
                    <p>
                        Important Notice: These Terms of Service contain provisions that: <br />
                        (1) mandate binding arbitration for dispute resolution;<br />
                        (2) waive your right to participate in a class action lawsuit against us; and <br/>
                        (3) release us from liability for any damages arising from your use of the Services.
                    </p>
                    <p>
                        Waiver of Rights: These Terms of Service significantly affect your legal rights,
                        including limitations on liability and available remedies.
                        You acknowledge that you have the right to consult with an attorney before accepting these Terms.
                        By agreeing to these Terms, you expressly acknowledge that you have read and
                        understood the entirety of this document and are knowingly waiving substantial rights.
                    </p>
                    <p>
                        Voluntary Agreement: You are under no obligation to use the Services or agree to these Terms.
                        Your continued use of the Services constitutes your voluntary acceptance of these Terms,
                        as may be updated by Green Growers Club at its sole discretion.
                    </p>

                    <hr />

                    <h2>About Green Growers Club</h2>
                    <p>
                        Green Growers Club is a mobile application that provides users with information on cannabis cultivation,
                        including cultivar care, strain selection, and harvesting techniques.
                        The Services are intended for educational and informational purposes only and are not a substitute for professional advice.
                        Green Growers Club does not sell cannabis products.
                    </p>

                    <hr/>

                    <h2>Governing Law and Jurisdiction</h2>
                    <p>
                        Your use of the Services is subject to the laws of the jurisdiction in which you access and use them.
                        You represent and warrant that you are familiar with and will comply with all applicable laws.
                        Except for disputes with Green Growers Club, which are governed by Articles 6 and 7 of these Terms,
                        any disputes arising from your use of the Services will be resolved in accordance with the laws of the jurisdiction
                        where you access and use them. We reserve the right to deny or terminate your access to the Services for any reason,
                        including but not limited to your non-compliance with applicable laws or our determination that your use of the Services
                        is prohibited in your jurisdiction. We also retain the right to restrict access to the Services based on your geographical location.
                    </p>

                    <hr/>

                    <h2>Federal Law Compliance</h2>
                    <p>
                        While certain states have legalized various forms of cannabis, the cultivation, manufacture, distribution, possession, and facilitation
                        of cannabis-related activities remain illegal under federal law.
                        Green Growers Club makes no representations to the contrary and assumes no responsibility for ensuring your compliance with federal or state laws.
                        You are solely responsible for understanding and adhering to all applicable laws in your jurisdiction.
                        Green Growers Club shall not be liable for any civil, criminal, or administrative actions, proceedings, or penalties arising from your use of the Services.
                        It is strictly prohibited to transport any federally controlled substance across state or international borders.
                    </p>

                    <hr/>

                    <h2>Assumption of Risk</h2>
                    <p>
                        You expressly acknowledge and assume all risks, whether known or unknown, foreseeable or unforeseeable, associated with
                        your use of the Services. This includes, but is not limited to, personal liability, injury, medical treatment, loss, death,
                        criminal charges, or any other damages that may arise directly or indirectly from your use of the Services.
                        You are solely responsible for ensuring your actions comply with all applicable laws and regulations.
                        You hereby waive and release any and all claims against Green Growers Club, its owners, officers, employees, successors, agents, and
                        assigns, arising from any activities you undertake in connection with the Services.
                        You fully understand and accept the inherent risks associated with your use of the Services.
                    </p>

                    <hr/>

                    <h2>Account Creation and Use</h2>
                    <p>
                        Eligibility and Registration: To access and use the Services, you must be at least twenty-one (21) years of age.
                        You may create an account ("Account") to utilize certain features of the Services. Account registration requires you to
                        provide accurate and complete information, including your email address and other information as reasonably requested by
                        Green Growers Club. You are responsible for maintaining the accuracy and confidentiality of your Account information.
                    </p>
                    <p>
                        Account Security and Responsibility: You are solely responsible for all activities that occur under your Account.
                        Green Growers Club shall not be liable for any unauthorized access to or use of your Account. If you suspect any unauthorized activity or
                        security breach related to your Account, you must immediately notify us at info@greengrowers.club and cease all use of the Services.
                    </p>

                    <hr/>

                    <h2 id='privacy-policy'>Privacy and Acceptable Use</h2>
                    <p>
                        Privacy Policy:  Our collection, use, and sharing of your information are governed by our Privacy Policy, available on the Website.
                        We encourage you to review this policy carefully. By making your Green Growers Club information "Public," you consent to your profile, including
                        your username, description, logs, posts, photos, and other provided information, being accessible to all users, including
                        those outside the Green Growers Club network.
                    </p>

                    <h3>Acceptable Use Policy:</h3>
                    <h4>Compliance</h4>
                    <ul>
                        <li>You may only use the Services if you are legally competent to agree to these Terms and are at least twenty-one (21) years of age.</li>
                        <li>Your use of the Services must comply with all applicable federal, state, and local laws and regulations.</li>
                        <li>You shall not facilitate or promote any illegal activities, including but not limited to the sale or purchase of illegal drugs, the depiction or encouragement of drug use by minors, or the provision of instructions for cultivating or manufacturing illegal drugs.</li>
                        <li>You shall not use the Services for any fraudulent, offensive, indecent, objectionable, or harmful purposes, or to cause nuisance, annoyance, or inconvenience.</li>
                        <li>You shall provide Green Growers Club with any requested proof of identity or other verification documents.</li>
                    </ul>

                    <p>General Prohibitions: You shall not collect, upload, transmit, display, or distribute any content ("Content") that:</p>
                    <ul>
                        <li>Infringes on any third-party rights, including intellectual property or privacy rights.</li>
                        <li>Is unlawful, harassing, abusive, threatening, defamatory, false, misleading, obscene, or otherwise objectionable.</li>
                        <li>Promotes harmful or discriminatory conduct.</li>
                        <li>Violates any applicable law, regulation, or third-party obligation.</li>
                    </ul>

                    <p>Technological Restrictions:</p>
                    <ul>
                        <li>You shall not upload or distribute any malicious software or content.</li>
                        <li>You shall not send unsolicited or unauthorized communications, including spam or chain letters.</li>
                        <li>You shall not collect information about other users without their consent.</li>
                        <li>You shall not interfere with or disrupt the Services or connected networks.</li>
                        <li>You shall not impersonate any person or entity or gain unauthorized access to the Services.</li>
                        <li>You shall not harass or interfere with other users' enjoyment of the Services.</li>
                        <li>You shall not use automated means to create multiple accounts, generate automated requests, or scrape data from the Services.</li>
                    </ul>

                    <p>Representations and Warranties</p>
                    <ul>
                        <li>You represent and warrant that you will not reverse engineer, decompile, or disassemble any part of the Services except as permitted by applicable law.</li>
                        <li>You represent and warrant that you will not attempt to discover the source code of the Services.</li>
                        <li>You represent and warrant that you will not remove or alter any copyright or proprietary notices.</li>
                        <li>You represent and warrant that you will not circumvent any access controls or protections of the Services.</li>
                        <li>You represent and warrant that you will not distribute, sell, or otherwise exploit the Services in any unauthorized manner.</li>
                        <li>You represent and warrant that you will not consequences of Breach:  We reserve the right to remove any Content that violates this Acceptable Use Policy. Any breach of these restrictions may result in the immediate termination of your limited license to use the Services and potential legal action.</li>
                    </ul>

                    <p>
                        Consequences of Breach:  We reserve the right to remove any Content that violates this Acceptable Use Policy.
                        Any breach of these restrictions may result in the immediate termination of your limited license to use the Services and potential legal action.
                    </p>

                    <hr/>

                    <h2>App License, Restrictions, and Ownership</h2>
                    <p>
                        Limited License: Subject to your compliance with these Terms, Green Growers Club grants you a personal, non-exclusive, non-transferable, revocable,
                        limited license to download, install, access, and use the Services solely on devices you own or control, for your personal, non-commercial use only.
                        This license is limited to Green Growers Club's intellectual property rights and does not include any rights to third-party intellectual property.
                        All rights not expressly granted herein are reserved by Green Growers Club. This license does not constitute a sale of any software.
                    </p>
                    <p>
                        Fees and Billing: Green Growers Club will provide advance notice before charging any fees for the use of the Services.
                        Billing will occur in 30-day or 365-day intervals, encompassing the base fee and any applicable percentage of goods fees.
                        Invoices will be sent to the Account Owner's email address and will also be available on the account page of your device's store administration console.
                        You have approximately two weeks from the invoice date to raise and resolve any billing disputes.
                    </p>
                    <p>
                        Ownership and Intellectual Property: The Services and their Content, including their "look and feel" (e.g., text, graphics, images, logos),
                        are protected by intellectual property laws. You acknowledge that Green Growers Club and/or its licensors own all right, title, and
                        interest in and to the Services and all associated intellectual property rights, including any derivatives or improvements.
                    </p>
                    <p>
                        Any feedback, suggestions, or modifications you provide ("Feedback") and any improvements, updates, or enhancements to the Services ("Revisions"),
                        whether made by you or Green Growers Club, shall be the sole and exclusive property of Green Growers Club. You hereby assign all right, title, and
                        interest in any Feedback or Revisions to Green Growers Club. Upon request, you will execute any documents necessary to effectuate this assignment.
                    </p>

                    <hr/>

                    <h2>User Content and Ownership</h2>
                    <p>Definitions:</p>
                    <ul>
                        <li>"Content" means any text, images, photos, audio, video, graphics, reviews, location data, and other forms of data, communication, or media.</li>
                        <li>"Your Content" means Content you submit or transmit to, through, or in connection with the Services, excluding Feedback and Revisions.</li>
                        <li>"User Content" means Content submitted or transmitted by other users of the Services.</li>
                        <li>"Green Growers Club Content" means Content created or owned by Green Growers Club and made available in connection with the Services.</li>
                        <li>"Third-Party Content" means Content originating from parties other than Green Growers Club or users, made available in connection with the Services.</li>
                        <li>"General Content" means all Content made available in connection with the Services, including Your Content, User Content, Third-Party Content, and Green Growers Club Content.</li>
                        <li>License Grant: By submitting or posting Your Content through the Services, you grant Green Growers Club a non-exclusive, perpetual, irrevocable, royalty-free, worldwide, transferable, and sublicensable license to access, reproduce, transmit, display, publish, distribute, modify, adapt, and create derivative works from Your Content.</li>
                    </ul>

                    <p>Your Content Representations and Warranties:</p>
                    <ul>
                        <li>You represent and warrant that you own or have the necessary rights to all of Your Content, including all copyrights, and that Your Content does not infringe upon any third-party rights.</li>
                        <li>You represent and warrant that your Content is accurate and does not violate these Terms or our Privacy Policy.</li>
                        <li>You represent and warrant that your Content will not cause injury to any person or entity.</li>
                        <li>You represent and warrant that disclaimer: Green Growers Club assumes no responsibility or liability for any Content submitted or posted through the Services, including any forums, by you or any third party.</li>
                    </ul>

                    <p>
                        User Content Disclaimer: Green Growers Club assumes no responsibility or liability for any Content submitted or posted through the Services,
                        including any forums, by you or any third party.
                    </p>

                    <p>Review Guidelines:</p>
                    <p>
                        Accurate and Personal Reviews: You agree to post only reviews based on your own personal experiences with the products or
                        services offered through the Services. Reviews must be truthful, accurate, and not misleading. Reviews based on secondhand or
                        non-personal experiences are strictly prohibited.
                    </p>

                    <p>Prohibited Content: In addition to the restrictions outlined in the Acceptable Use Policy, reviews posted on the Services must not:</p>
                    <ul>
                        <li>Be written exclusively in capital letters.</li>
                        <li>Be plagiarized from any other source.</li>
                        <li>Contain spam, advertisements, or promotional content.</li>
                        <li>Include personally identifiable information about any individual.</li>
                        <li>Contain detailed or sexual descriptions of an individual's physical appearance, or lewd personal attacks against any individual or group.</li>
                        <li>Reference businesses or their competitors, except for the specific product or business being reviewed.</li>
                        <li>Contain unrelated personal grievances or complaints.</li>
                    </ul>

                    <p>
                        Conflict of Interest: If you are an owner, employee, consultant, agent, or volunteer of a business, you may not post reviews
                        about that business, its products, or its competitors.
                    </p>

                    <p>
                        Content Removal: We reserve the right to remove any Content, including reviews, that we determine, in our sole discretion,
                        violates any law, infringes upon the rights of any person, or is otherwise inappropriate for posting on the Services, including but
                        not limited to violations of the Acceptable Use Policy.
                    </p>

                    <p>
                        Green Growers Club reserves the right to monitor, review, and moderate all reviews posted on the Services.
                        We may, in our sole discretion, remove or edit any review that violates these Terms, our Acceptable Use Policy, or any applicable law.
                        While we strive to maintain a fair and transparent review environment, we do not guarantee the accuracy, completeness, or
                        timeliness of any review. If you have a dispute or complaint regarding a review, please contact us at info@greengrowers.club.
                    </p>

                    <p>Specific Prohibitions:</p>
                    <p>In addition to the general prohibitions listed above, reviews must not:</p>
                    <ul>
                        <li>Promote or facilitate the illegal use or distribution of cannabis or any other controlled substance.</li>
                        <li>Contain inaccurate or misleading information about cannabis strains, products, or effects.</li>
                        <li>Encourage or endorse unsafe or irresponsible cannabis consumption practices.</li>
                        <li>Disclose confidential or proprietary information about Green Growers Club or any third party.</li>
                        <li>Include any content that is defamatory, discriminatory, or hateful towards any individual or group.</li>
                        <li>These specific prohibitions are tailored to the nature of Green Growers Club's services and are intended to promote a safe, informative, and responsible community for cannabis enthusiasts.</li>
                    </ul>

                    <hr/>

                    <h2>Notice of Claimed Infringement</h2>
                    <p>Digital Millennium Copyright Act</p>
                    <p>
                        If you believe that any Content on the Services infringes your copyright, you may submit a notification of claimed infringement to
                        our designated Copyright Agent in accordance with the Digital Millennium Copyright Act ("DMCA"). To be valid,
                        your notification must be in writing and include the following information:
                    </p>
                    <ul>
                        <li>Identification of the copyrighted work claimed to be infringed.</li>
                        <li>Identification of the allegedly infringing material on the Services, including a description of its location.</li>
                        <li>Your contact information, including your name, address, telephone number, and email address.</li>
                        <li>A statement that you have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
                        <li>A statement, under penalty of perjury, that the information in the notification is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
                        <li>Your physical or electronic signature.</li>
                        <li>If you are alleging infringement of an intellectual property right other than copyright (e.g., trademark), please specify the relevant right in your written notice. Failure to comply with all of the above requirements may render your DMCA notice invalid.</li>
                    </ul>

                    <p>Designated Copyright Agent</p>

                    <p>Written notifications of claimed copyright infringement should be sent to our designated Copyright Agent:</p>

                    <p>By Mail:  Green Growers Club 1942 Broadway St., Ste. 314C, Boulder CO 80302, USA</p>

                    <p>By Email: info@greengrowers.club</p>

                    <p>
                        We will respond expeditiously to properly submitted notices of claimed copyright infringement.
                        In appropriate circumstances and at our sole discretion, we may disable or terminate the accounts of users who repeatedly infringe the copyrights or
                        other intellectual property rights of others.
                    </p>

                    <p>Counter-Notification Procedure:</p>

                    <p>
                        If you believe that your Content was removed or disabled in error or due to misidentification, you may submit a counter-notification to our
                        Copyright Agent. Your counter-notification must be in writing and include the following:
                    </p>
                    <ul>
                        <li>Your physical or electronic signature, along with your full legal name.</li>
                        <li>Identification of the Content that was removed or disabled and the location where it appeared before removal or disablement.</li>
                        <li>A statement, under penalty of perjury, that you have a good faith belief that the Content was removed or disabled as a result of mistake or misidentification.</li>
                        <li>Your contact information, including your name, address, telephone number, and email address, and a statement that you consent to the jurisdiction of the federal district court for the judicial district in which your address is located (or, if your address is outside of the United States, for any judicial district in which Green Growers Club may be found) and that you will accept service of process from the person who provided the original notification of claimed infringement.</li>
                        <li>Green Growers Club's Response to Counter-Notifications:</li>
                    </ul>

                    <p>
                        Upon receipt of a counter-notification, we may forward a copy to the original complaining party, informing them that we may
                        reinstate the removed Content or cease disabling it. Unless the complaining party initiates a court action seeking
                        an order against you, we may, in our sole discretion, reinstate the removed Content or cease disabling it within
                        ten (10) business days or more after receiving your counter-notification.
                    </p>

                    <p>
                        Caution: Please be aware that submitting a counter-notification may result in legal proceedings between you and
                        the complaining party to determine ownership of the Content. Making false or bad faith statements in a counter-notification may
                        result in legal consequences.
                    </p>

                    <p>
                        Additional Information: For further information on the DMCA, please refer to 17 U.S.C. 512 or visit the United States
                        Copyright Office website at https://www.copyright.gov/legislation/dmca.pdf.
                    </p>

                    <hr/>

                    <h2>Third-Party Terms and Providers</h2>

                    <p>
                        Apple App Store: If you obtain the App from the Apple App Store, the following applies:
                    </p>
                    <ul>
                        <li>Relationship with Apple: These Terms are solely between you and Green Growers Club. Apple is not a party to these Terms and has no responsibility for the App or its content.</li>
                        <li>App Store Terms: Your use of the App must comply with the Apple App Store Terms of Service.</li>
                        <li>Apple's Limited Role: Apple has no obligation to provide maintenance or support for the App. If the App fails to conform to any applicable warranty, you may notify Apple, and Apple's sole remedy may be to refund the purchase price of the App.</li>
                        <li>Disclaimer of Warranties: To the maximum extent permitted by applicable law, Apple disclaims all other warranties related to the App. Any claims arising from the App's failure to conform to any warranty will be governed solely by these Terms.</li>
                        <li>Third-Party Claims: Apple is not responsible for addressing any third-party claims related to the App or your use of it, including product liability claims, claims of non-compliance with laws or regulations, or consumer protection claims.</li>
                        <li>Intellectual Property Claims: If any third party claims that the App infringes their intellectual property rights, Green Growers Club, not Apple, will be solely responsible for the investigation, defense, settlement, and discharge of such claims.</li>
                        <li>Third-Party Beneficiary: Apple and its subsidiaries are third-party beneficiaries of these Terms as they relate to your license of the App, and they have the right to enforce these Terms against you.</li>
                    </ul>

                    <p>
                        Google Play Store: If you obtain the App from the Google Play Store, the Google Play Terms of Service and Google Play Business and Program Policies (or any successor terms designated by Google) will apply to the extent of any conflict with these Terms. Green Growers Club and you acknowledge that Google has no responsibility or liability related to compliance or non-compliance with these Terms or the Google Play Terms.
                    </p>

                    <hr/>

                    <h2>Disclaimer of Warranties and Limitation of Liability</h2>
                    <p>
                        No Warranties: The Services are provided "as is" and "as available," without any warranties of any kind, whether express,
                        implied, or statutory. Green Growers Club expressly disclaims all warranties, including but not limited to the implied warranties of
                        merchantability, fitness for a particular purpose, title, and non-infringement. Green Growers Club does not warrant that
                        the Services will meet your requirements, be uninterrupted or error-free, be compatible with any other application or system, or
                        that any defects will be corrected. You acknowledge that your use of the Services is at your sole risk.
                    </p>

                    <p>
                        Release and Limitation of Liability: To the fullest extent permitted by law, you release and forever discharge
                        Green Growers Club (and its officers, directors, employees, agents, successors, shareholders, and assigns) from any and
                        all claims, liabilities, damages, losses, costs, and expenses arising from or relating to your use of the Services, including but
                        not limited to any interactions with other users or third-party materials.
                    </p>

                    <p>
                        In no event shall Green Growers Club's total liability to you or any third party for any damages arising from or related to
                        these Terms or the use of the Services, whether in contract, tort (including negligence), or otherwise, exceed the lesser of
                        (i) the amounts paid by you to Green Growers Club for the use of the Services, or (ii) four dollars and twenty cents ($4.20).
                    </p>

                    <p>
                        Assumption of Risk: By accessing or using the Services, you acknowledge that you may be waiving rights with respect to
                        claims that are currently unknown or unsuspected.
                    </p>

                    <hr/>

                    <h2>Indemnification</h2>
                    <p>
                        You agree to indemnify, defend, and hold harmless Green Growers Club and its officers, directors, employees, agents, successors,
                        shareholders, and assigns from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees
                        (including reasonable attorneys' fees) arising from or relating to:
                    </p>
                    <ul>
                        <li>Your breach of these Terms or any applicable law or regulation.</li>
                        <li>Your violation of any third-party rights.</li>
                        <li>Any unauthorized use of the Services.</li>
                        <li>Your negligence or willful misconduct.</li>
                        <li>Your use of the Services.</li>
                    </ul>

                    <hr/>

                    <h2>Arbitration Agreement and Class Action Waiver</h2>
                    <p>
                        Agreement to Arbitrate: Any dispute or claim arising out of or relating to your use of the Services, or the breach or validity of
                        these Terms, shall be resolved exclusively through final and binding arbitration, except for disputes relating to the enforcement or
                        validity of our intellectual property rights or claims that you have violated the Acceptable Use Policy.
                    </p>
                    <ul>
                        <li>No Judicial Recourse: You and Green Growers Club waive the right to a trial by jury or to participate in a class action lawsuit or class-wide arbitration.</li>
                        <li>Arbitration Procedures: The arbitration will be administered by the American Arbitration Association ("AAA") under its Commercial Arbitration Rules and the Supplementary Procedures for Consumer-Related Disputes, as modified by these Terms. The arbitrator shall be a single, neutral arbitrator appointed in accordance with the AAA rules. The arbitrator's decision shall be final and binding, and judgment on the award may be entered in any court having jurisdiction.</li>
                        <li>Governing Law: The arbitration shall be governed by the Federal Arbitration Act and the laws of the State of Colorado, without regard to its conflict of laws principles.</li>
                    </ul>
                    <p>
                        Initiation and Conduct of Arbitration:
                    </p>
                    <p>
                        Commencing Arbitration: To initiate arbitration, you must send a written Notice of Dispute to Green Growers Club at the following
                        address: 1942 Broadway St., Ste. 314C, Boulder CO 80302, USA.
                        The Notice of Dispute must include your name, address, contact information, the facts giving rise to the dispute, and
                        the relief sought. Green Growers Club will send any Notice of Dispute to you at the contact information we have on file.
                    </p>
                    <p>
                        Arbitration Format: The arbitration may be conducted, at the election of the party initiating the arbitration, by telephone, online, or based solely on written submissions.
                    </p>
                    <p>
                        Arbitration Fees: If you initiate arbitration, your arbitration fees will be limited to the filing fee set forth in the AAA's Consumer Arbitration Rules. Each party shall bear its own attorneys' fees and expenses, unless the arbitrator finds that the arbitration was frivolous or brought for an improper purpose, in which case you agree to reimburse Green Growers Club for all costs and expenses.
                    </p>
                    <p>
                        Individual Proceedings and Venue: Any dispute resolution proceeding will be conducted on an individual basis only and not in a
                        class, consolidated, or representative action. If, for any reason, a claim proceeds in court rather than arbitration, both you and
                        Green Growers Club waive any right to a jury trial. In such an event, the exclusive jurisdiction and venue for
                        any litigation shall be the state courts located in Boulder, Colorado, and both parties consent to the personal jurisdiction of
                        such courts.
                    </p>
                    <p>
                        Limitations Period: Any claim, action, or proceeding must be commenced within one (1) year after the cause of action accrues.
                    </p>
                    <p>
                        Enforcement: Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
                    </p>
                    <p>
                        Severability: If any portion of this arbitration provision is deemed invalid or unenforceable, the remaining portions shall remain
                        in full force and effect.
                    </p>
                    <p>
                        Opt-Out Right: If you are an individual and do not wish to be bound by this arbitration agreement, you may opt out by sending
                        an email to info@greengrowers.club within thirty (30) days of your first use of the Services.
                    </p>

                    <hr/>

                    <h2>General Provisions</h2>
                    <p>
                        **Termination**:
                    </p>
                    <p>
                        Green Growers Club may terminate your access to the Services or delete your Account, with or without notice, for any reason or
                        no reason, including but not limited to your breach of these Terms or any applicable law or regulation. Upon termination,
                        your license to use the Services shall immediately cease, and you must cease all use of the Services.
                        Your indemnification obligations and any other provisions of these Terms that by their nature should survive termination shall
                        remain in full force and effect.
                    </p>
                    <p>
                        **Injunctive Relief**: You acknowledge that a breach of these Terms may cause irreparable harm to Green Growers Club,
                        for which monetary damages would be inadequate. Green Growers Club shall be entitled to seek injunctive or other equitable relief
                        in addition to any other remedies available at law or in equity.
                    </p>
                    <p>
                        **Notices**:
                    </p>
                    <p>
                        Any notices to you will be provided via email to the address associated with your Account or by posting a general notice on the
                        Services. Such notice shall be deemed effective upon transmission or posting. You may provide notice to us by
                        emailing info@greengrowers.club. Please include a descriptive subject line to facilitate proper routing.
                    </p>
                    <p>
                        **U.S. Government Sanctions**: You represent and warrant that you are not located in, under the control of, or a national or
                        resident of any country subject to U.S. government embargoes or sanctions or designated as a "terrorist supporting" country.
                        You further represent and warrant that you are not listed on any U.S. government list of prohibited or restricted parties.
                    </p>

                    <h2>Miscellaneous</h2>
                    <ul>
                        <li>Modification**: These Terms may only be modified by a written agreement signed by authorized representatives of both parties.</li>
                        <li>Assignment**: Green Growers Club may assign these Terms without your consent. You may not assign these Terms without Green Growers Club's prior written consent.</li>
                        <li>Severability**: If any provision of these Terms is deemed invalid or unenforceable, the remaining provisions shall remain in full force and effect.</li>
                        <li>Waiver**: No waiver of any breach or default of these Terms shall constitute a waiver of any subsequent breach or default.</li>
                        <li>Relationship of the Parties**: These Terms do not create any agency, partnership, joint venture, or employment relationship between you and Green Growers Club.</li>
                        <li>Force Majeure**: Neither party shall be liable for any delay or failure in performance due to events beyond its reasonable control, such as acts of God, natural disasters, or governmental actions.</li>
                        <li>Headings**: Headings are for convenience only and do not affect the interpretation of these Terms.</li>
                        <li>Governing Law and Venue**: These Terms shall be governed by and construed in accordance with the laws of the State of Colorado, without regard to its conflict of laws principles. Any disputes arising out of or relating to these Terms or the Services shall be subject to the exclusive jurisdiction of the state or federal courts located in Boulder, Colorado, and both parties consent to the personal jurisdiction of such courts.</li>
                        <li>Entire Agreement**: These Terms constitute the entire agreement between you and Green Growers Club regarding the Services and supersede all prior or contemporaneous communications and proposals, whether oral or written.</li>
                    </ul>

                    <h2>Modifications to Terms</h2>
                    <p>
                        We may update these Terms from time to time. Any material changes will be communicated to you via email or through a prominent notice on the Services.
                        Your continued use of the Services after any such changes constitutes your acceptance of the new Terms.
                    </p>

                    <h2>Contact us</h2>
                    <p>If you have any questions or concerns regarding these Terms or the Services, please contact us at info@greengrowers.club.</p>
                </Col>
            </Row>
        </section>
    );
};

export default Footer;
